import logo from "./../assets/images/logo.png";
import Typewriter from "typewriter-effect";

type HeaderProps = {
  onClickHandler: () => void;
};

const Header = ({ onClickHandler }: HeaderProps) => {
  return (
    <header className="flex flex-col p-8">
      <div className="flex flex-col justify-center sm:flex-row">
        <img
          className="h-52 object-contain p-4"
          src={logo}
          alt="Rolf.Bible"
          onClick={onClickHandler}
        />
        <div className="content-center p-4 text-center">
          <span className="relative h-20 w-full overflow-hidden whitespace-pre-line text-7xl font-extrabold tracking-tight text-white">{`Rolf\n.Bible`}</span>
        </div>
      </div>

      <div className="h-20 text-center text-3xl font-bold text-white md:h-auto">
        <Typewriter
          options={{
            strings: [
              "Software ontwikkelen voor Bijbelvertalers",
              "Totdat iedereen een Bijbel heeft!",
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 20,
          }}
        />
      </div>
    </header>
  );
};

export default Header;
